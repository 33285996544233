import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"


const ContactMe = () => {
  return (
    <PageLayout>
      <SEO
        title="Book A Shop Appointment With King Electric Guitars"
        ogDescription="Want to build your dream guitar but have no idea where to start? Give me a call and I'll guide you through the process."
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>
              Contact Randy King at King Electric Guitars
            </h1>
            <p>
              Give me a call or fill out the form below to start a converstation about building your dream guitar.
            </p>
            <p>
              I'm happy to have you down to my shop in Griffin, GA. But it's by appointment only, so get in touch with me to set something up.
            </p>
            <p>
              Give me a call at <a href="tel:4045143462">(404) 514-3462</a>
            </p>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default ContactMe
